import React, { Component } from "react";
import axios from "axios";
import AuctionDetails from "./AuctionDetails";
import Loader from "./Loader";
import "./app.css";
import Menu from "./Menu";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";


class AuctionSingle extends Component {
    state = {
        auction: [],
        images: '',
        titleSliced: '',
        auctionsadmins: [],
        loader: false,
        url: apiDomainForComponents+"/api/auction/" + this.props.match.params.id,
        urladmin: apiDomainForComponents+"/api/administration/"+this.props.match.params.id,
        user: localStorage.getItem('user-email')
    }
    getAuction = async () => {
        this.setState({loader: true});
        const auction = await axios.get(this.state.url);
        this.setState({ auction: auction.data,images: auction.data.images_content, loader: false, titleSliced: auction.data.title});
        const auctionsadmins = await axios.get(this.state.urladmin);
        this.setState({ auctionsadmins: auctionsadmins.data, loader: false});
    };


    componentDidMount() {
        this.getAuction();
    }

    render() {

        return <div>
       <Menu/>
            <div className="ui main container">
                {
                    this.state.loader ? <Loader /> : ""
                }
                <AuctionDetails auction={this.state.auction} auctionsadmins={this.state.auctionsadmins} images={this.state.images} titleSliced={this.state.titleSliced.slice(0, -14)}/>
            </div>
        </div>
    }
}

export default AuctionSingle;