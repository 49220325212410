import React, { Component } from "react";
import NewAuction from "./NewAuction";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";

class NewAuctionList extends Component {
    constructor() {
        super();
        this.state = {
            search: ''
        }
    }

    updateSearch(event) {
        this.setState({search: event.target.value.toLowerCase().substr(0,20)});
    }
    render() {
        const auctions = this.props.auctions;
        const favorites = this.props.favorites;
        //console.log(favorites);
        let filteredAuctions = auctions.filter(
            (auction) =>{
                return auction.title.toUpperCase().indexOf(this.state.search) !== -1 || auction.title.toLowerCase().indexOf(this.state.search) !== -1 || auction.title.indexOf(this.state.search) !== -1 || auction.insurance.indexOf(this.state.search) !== -1 || auction.insurance.toLowerCase().indexOf(this.state.search) !== -1 || auction.insurance.toUpperCase().indexOf(this.state.search) !== -1;
            }
        );
        return (

          <div className="data">
              <div className="ui input">
              <input type="text" value={this.state.search} onChange={this.updateSearch.bind(this)} placeholder="Wyszukaj"/>
              </div>
                  <table className="ui celled table">
                  <thead>
                  <tr>
                      <th>Ulubione</th>
                      <th style={{width: "148px"}}></th>
                      <th>Ubezpieczalnia</th>
                      <th>Tytuł</th>
                      <th>Koniec</th>
                      <th style={{ width: "50px", textAlign: "center"}}>#</th>
                  </tr>
                  </thead>

                  <tbody>
                  {filteredAuctions.map((auctions) => {
                      return (
                              <NewAuction favorites={favorites} auctions={auctions} key={auctions.id}/>

                          )
                      })}
                  </tbody>
              </table>
          </div>
        );
    }
}

export default NewAuctionList;