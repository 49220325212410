import React, { Component } from "react";
import axios from "axios";
import NewAuctionListForQuests from "./NewAuctionListForQuests";
import Loader from "./Loader";
import MenuQuests from './MenuQuests';
import "./app.css";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";


class NewAuctionsForQuests extends Component {
    state = {
        favorites: [],
        auctions: [],
        loader: false,
        url: apiDomainForComponents+"/api/auctions",
    }
    getAuctions = async () => {
        this.setState({loader: true});
      const auctions = await axios.get(this.state.url);
      this.setState({ auctions: auctions.data, loader: false});
    };

    componentDidMount() {
        this.getAuctions();
    }


    render() {

        return <div>
            <MenuQuests/>
                <div className="ui main container">
                    <h3 className="ui center aligned header">❗❗ Za pojazdy kupione w ubezpieczalni AXA pobierana jest dodatkowa opłata za wygraną aukcję:<br/>
                        do 999chf bez opłaty,<br/>
                        od 1000 do 4999chf opłata 90chf,<br/>
                        powyżej 5000chf opłata 120chf.<br/>
                        ❗❗ Za pojazdy kupione w ubezpieczalni Allianz pobierana jest dodatkowa opłata za wygraną aukcję:<br/>
                        do 999chf bez opłaty,<br/>
                        od 1000 do 4999chf opłata 70chf,<br/>
                        powyżej 5000chf opłata 110chf.<br/></h3>
                    {
                        this.state.loader ? <Loader /> : ""
                    }
                    <NewAuctionListForQuests favorites={this.state.favorites} auctions={this.state.auctions}/>
                </div>
            </div>
    }
}

export default NewAuctionsForQuests;