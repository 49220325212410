import React, { Component } from "react";
import axios from "axios";
import FavoritesList from "./FavoritesList";
import Loader from "./Loader";
import "./app.css";
import Menu from "./Menu";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";


class MyFavorites extends Component {
    state = {
        offers: [],
        loader: false,
        url: apiDomainForComponents+"/api/favorites-auctions/"+localStorage.getItem('user-email'),
        user: localStorage.getItem('user-email')
    }
    getOffers = async () => {
        this.setState({loader: true});
        const offers = await axios.get(this.state.url);
        this.setState({ offers: offers.data, loader: false});
    };
    componentDidMount() {
        this.getOffers();
    }

    render() {

        return <div>
      <Menu/>
            <div className="ui main container">
                {
                    this.state.loader ? <Loader /> : ""
                }
                <FavoritesList offers={this.state.offers}/>
            </div>
        </div>
    }
}

export default MyFavorites;