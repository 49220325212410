import React, { Component } from "react";
import Favorite from "./Favorite";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";

class FavoritesList extends Component {

    render() {
        const offers = this.props.offers;

        return (

            <div className="data">
                <table className="ui celled table">
                    <thead>
                    <tr>
                        <th style={{width: "148px"}}></th>
                        <th>Ubezpieczalnia</th>
                        <th>Aukcja</th>
                        <th>Koniec</th>
                        <th>Usuń z ulubionych</th>
                    </tr>
                    </thead>

                    <tbody>
                    {offers.map((offer) => {
                        return (
                            <Favorite offer={offer} key={offer.id}/>
                        )
                    })}


                    </tbody>
                </table>
            </div>
        );
    }
}

export default FavoritesList;