import React, { Component } from "react";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class Offer extends Component {


    render() {
        const { auction_id, is_win,price, insurance,auction_title } = this.props.offer;
        return (
            <tr>

                <td><a href={'/aukcja/'+auction_id}>{auction_title}</a></td>
                <td>{insurance}</td>
                <td>{price}</td>
                {is_win ? (

                    <td style={{color: "green"}}>Wygrano</td>
                ) : (
                    <td style={{color: "red"}}>Nie zatwierdzono jako wygraną</td>
                )
                }
            </tr>
        );
    }

}

export default Offer;