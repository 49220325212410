import React, { Component } from "react";
import "./app.css";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";
import axios from "axios";

class MenuQuests extends Component {

  state = {
    auctions: [],
    loader: false,
    url: apiDomainForComponents+"/api/auctions",
    user: localStorage.getItem('user-email'),

}

  getAuctions = async () => {
    this.setState({loader: true});
  const auctions = await axios.get(this.state.url);
  this.setState({ auctions: auctions.data, loader: false});
  };

  componentDidMount() {
    this.getAuctions();
}

    render() {
      var arrayOfTrue = [];
        this.state.auctions.forEach((e) => {
          var date1 = new Date(e.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
        if (is24) { arrayOfTrue.push(true) } 
        })
        const count = arrayOfTrue.length;
        const countAll = this.state.auctions.length;
        return (
            <div>
                  <nav className="navbar navbar-expand-lg" style={{backgroundColor: 'white'}}>
      <div className="container-fluid">
      <a className="navbar-brand" href="#"> MOTOCARS <br/> tel. +48 577 982 369</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
            <a className="nav-link" href="/">Lista aukcji<span className="badge badge-secondary"> {countAll} </span></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/nowe-aukcje">
                   
                        Nowe aukcje<span className="badge badge-success"> {count} </span></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/zaloguj">Zaloguj</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  </div> )
    }
}

export default MenuQuests;