import React, { Component } from "react";
import axios from "axios";



class NewAuctionForQuests extends Component {

     render() {
        const {id, thumbnail_link, title, insurance,end_at,created_at,images_content} = this.props.auctions;
        var date1 = new Date(this.props.auctions.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
         return (
            is24 ? (
            <tr>
                <td style={{textAlign: "center"}}><a href={'/aukcja/'+id}><img src={'https://api.licytacjezeszwajcarii.pl/storage/'+(images_content.toString().split(','))[0]} style={{width: "200px"}} alt=""/></a></td>
                <td>{insurance}</td>
                <td>
                   <span class="badge badge-success"> Nowa </span>
                    <a href={'/zaloguj/'}>
                     {title.slice(0, -14)} </a></td>
                <td>{end_at}</td>
            </tr>
            ) : (
                null
              )
        );
    }

}

export default NewAuctionForQuests;