import React, { Component } from "react";
import axios from "axios";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class NewAuction extends Component {

    addFavorite = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/add-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

    dellFavorite = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/delete-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

     render() {
        const {id, thumbnail_link, title, insurance,end_at,created_at,images_content} = this.props.auctions;
         const {auction_id} = this.props.favorites;
         const obj = this.props.favorites;
         var date1 = new Date(this.props.auctions.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
         return (
            is24 ? (
            <tr>
                {(obj.includes(this.props.auctions.id)) ? (
                    <td style={{textAlign: "center",cursor: "pointer"}} onClick={() => this.dellFavorite(id)}><i className="far fa-star" style={{color: "red"}}></i></td>
                    ) :

                    ( <td style={{textAlign: "center",cursor: "pointer"}} onClick={() => this.addFavorite(id)}><i className="far fa-star"></i></td> )
                                }
                <td style={{textAlign: "center"}}><a href={'/aukcja/'+id}><img src={'https://api.licytacjezeszwajcarii.pl/storage/'+(images_content.toString().split(','))[0]} style={{width: "200px"}} alt=""/></a></td>
                <td>{insurance}</td>
                <td>
                   <span class="badge badge-success"> Nowa </span>
                    <a href={'/aukcja/'+id}>{title.slice(0, -14)}</a></td>
                <td>{end_at}</td>
                <td>{id}</td>
            </tr>
            ) : (
                null
              )
        );
    }

}

export default NewAuction;