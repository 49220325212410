import React, { Component } from "react";
import axios from "axios";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class AuctionDetails extends Component {

    constructor() {
        super();
        this.state = {
            price: '',
            user: localStorage.getItem('user-email')
        }
    }


    handleInput = (e) => {    this.setState({
        price: e.target.value,
    });  }

    saveOffer = async (e) => {
        e.preventDefault();
        let agree = window.confirm("Potwierdź złożenie oferty w wysokości "+ this.state.price + " CHF?");
        if (agree) {
            const res = await axios.post(apiDomainForComponents+'/api/add-offer', {
                price: this.state.price,
                auctionid: this.props.auction.id,
                useremail: localStorage.getItem('user-email'),
                title: this.props.titleSliced,
                insurance: this.props.auction.insurance,
            }).then(() => {
                window.alert('Poprawnie zalicytowano');
                window.location.reload(false);
            })
        }
        else {
            return false;
        }
        }



    render() {
        const {id, title, insurance,end_at, description_table} = this.props.auction;
        const imgArray = this.props.images.toString().split(',');
        const titleSliced = this.props.titleSliced;
        const auctionsadmins = this.props.auctionsadmins;
        return (
            <div>
            <h1> {titleSliced} {insurance} <br/> Data zakończenia: {end_at}</h1>
            <div dangerouslySetInnerHTML={{__html: description_table}}/>
                {imgArray.map((image) => {
                    return (
                        <img src={'https://api.licytacjezeszwajcarii.pl/storage/'+image} alt={image} key={image}/>
                    )
                })}
                {(document.referrer == (window.location.protocol + '//' + window.location.host + '/moje-oferty')) ? (<div></div>
                 ) : (   <div>
                    <h2>Złóż ofertę</h2>
                    <form onSubmit={this.saveOffer} className="ui form">
                        <div className="field">
                            <label>Cena w CHF</label>
                            <input type="number" name="price" value={this.state.price} onChange={this.handleInput}
                                   placeholder="Cena w CHF"/>
                            <input type="hidden" name="auctionid" value={id}/>
                            <input type="hidden" name="insurance" value={insurance}/>
                            <input type="hidden" name="title" value={titleSliced}/>
                            <input type="hidden" name="useremail" value={localStorage.getItem('user-email')}/>
                        </div>
                        <button className="ui button" type="submit">Zalicytuj</button>
                    </form>
                </div>)
                }
                               {((this.state.user === "hafenteile@gmail.com") || (this.state.user === secondAdminEmail)) ? (
                    <div className="data">
                <table className="ui celled table">
                    <thead>
                    <tr>
                        <th style={{width: "50px"}}>Data</th>
                        <th>Tytuł</th>
                        <th>Użytkownik</th>
                        <th>Cena</th>
                    </tr>
                    </thead>

                    <tbody>

                    {auctionsadmins.map((auctionsadmins) => {
                        return (
                            <tr>

                                <td>{auctionsadmins.created_at.split(".")[0].replace("T"," ")}</td>
                                <td>{auctionsadmins.auction_title}</td>
                                <td>{auctionsadmins.user_email}</td>
                                <td>{auctionsadmins.price} CHF</td>
                            </tr>
                        )
                    })}

                    </tbody>
                </table>
            </div>) : (null)
                    }
            </div>
        );
    }

}

export default AuctionDetails;