import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { ProtectedRoute } from "./common/router/protected";
import rootReducers from "./../store/reducers";
import Login from "./Login";
import Auctions from "./Auctions";
import AuctionSingle from "./AuctionSingle";
import MyOffers from "./MyOffers";
import Administration from "./Administration";
import AdministrationSingle from "./AdministrationSingle";
import PasswordReset from "./PasswordReset";
import PasswordChange from "./PasswordChange";
import AddUser from "./AddUser";
import BlockUser from "./BlockUser";
import MyFavorites from "./MyFavorites";
import Users from "./Users";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";
import AuctionsForQuests from "./AuctionsForQuests";
import NewAuctionsForQuests from "./NewAuctionsForQuests";
import NewAuctions from "./NewAuctions";

const store = createStore(rootReducers, applyMiddleware(thunk));

const guestRoutes = [
    { path: "/zaloguj", component: Login, exact: true },
    { path: "/przypomnij-haslo", component: PasswordReset, exact: true},
    { path: "/resetuj-haslo", component: PasswordChange, exact: true},
    { path: "/", component: AuctionsForQuests, exact: true },
    { path: "/nowe-aukcje", component: NewAuctionsForQuests, exact: true },

];

const protectedRoutes = [
    { path: "/lista-aukcji", component: Auctions, exact: true },
    { path: "/lista-nowych-aukcji", component: NewAuctions, exact: true },
    { path: "/aukcja/:id", component: AuctionSingle, exact: true },
    { path: "/moje-oferty", component: MyOffers, exact: true },
    { path: "/ulubione", component: MyFavorites, exact: true },
    { path: "/uzytkownicy", component: Users, exact: true },
    { path: "/dodaj-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb", component: AddUser, exact: true },
    { path: "/usun-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb", component: BlockUser, exact: true },
    { path: "/administracja-snfhfhmngfdsbhjjhgfgfffdddxcvbbb", component: Administration, exact: true },
    { path: "/administracja-snfhfhmngfdsbhjjhgfgfffdddxcvbbb/:id", component: AdministrationSingle, exact: true },
];

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    {guestRoutes.map((route, key) => {
                        return (
                            <Route
                                exact={route.exact}
                                path={route.path}
                                component={route.component}
                                key={key}
                            />
                        );
                    })}
                    <Provider store={store}>
                        {protectedRoutes.map((route, key) => {
                            return (
                                <ProtectedRoute
                                    exact={route.exact}
                                    path={route.path}
                                    component={route.component}
                                    key={key}
                                />
                            );
                        })}
                    </Provider>
                    <Route component={Error} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;