import React, { Component } from "react";
import axios from "axios";
import OffersAdminList from "./OffersAdminList";
import Loader from "./Loader";
import "./app.css";
import Menu from "./Menu";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";


class Administration extends Component {
    state = {
        auctionsadmin: [],
        loader: false,
        url: apiDomainForComponents+"/api/administration",
        user: localStorage.getItem('user-email')
    }
    getAuctionsAdmin = async () => {
        this.setState({loader: true});
        const auctionsadmin = await axios.get(this.state.url);
        this.setState({ auctionsadmin: auctionsadmin.data, loader: false});
    };
    componentDidMount() {
        this.getAuctionsAdmin();
    }

    render() {

        return <div>
            <Menu/>
            <div className="ui main container">
                {
                    this.state.loader ? <Loader /> : ""
                }
                {((this.state.user === "hafenteile@gmail.com") || (this.state.user === secondAdminEmail)) ? (
                    <OffersAdminList auctionsadmin={this.state.auctionsadmin}/>) : (<h1>Nie masz uprawnień dostępu do tej strony</h1>)
                }

            </div>
        </div>
    }
}

export default Administration;