let apiDomain = "";
if (process.env.NODE_ENV === "production") {
    apiDomain = "https://api.moto-cars.pl/";
} else {
    apiDomain = "https://api.moto-cars.pl/";
}

class UrlService {
    static loginUrl() {
        return apiDomain + "api/login";
    }
}

export default UrlService;